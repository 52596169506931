const tileData = [
  {
    href: "/tiles/anti-skid-tiles",
    src: "/ANTI SKID TILES.jpg",
    title: "Anti Skid Tiles",
  },
  {
    href: "/tiles/cool-tiles",
    src: "COOL ROOF TILES.jpg",
    title: "Cool Roof Tiles",
  },
  {
    href: "/tiles/elevation-tiles",
    src: "ELEVATION TILES.jpg",
    title: "Elevation Tiles",
  },
  {
    href: "/tiles/highlighter-tiles",
    src: "/HIGHLIGHTER TILES.jpg",
    title: "Highlighter Tiles",
  },
  // {
  //   href: "/tiles/forever-tiles",
  //   src: "/SCRATCH FREE TILES.jpg",
  //   title: "Scratch Free Tiles(Forever Tiles)",
  // },
  {
    href: "/tiles/large-tiles",
    src: "/LARGE SLAB TILES.jpg",
    title: "Large Slab Tiles",
  },
  // {
  //   href: "/tiles/parking-tiles",
  //   src: "/PARKING TILES.jpg",
  //   title: "Parking Tiles",
  // },
  // {
  //   href: "/tiles/wall-tiles",
  //   src: "/WALL TILES1.jpg",
  //   title: "Wall Tiles",
  // },
  // {
  //   href: "/tiles",
  //   src: "/view-all-tiles-black.jpg",
  //   title: "View All Tiles",
  //   className: "lastchild-color-bx", // optional extra class
  // },
];

function latestTrendingDesktop(props) {
  return (
    <div className="home-color-tile-row">
      {tileData.map(({ href, src, title, className = "" }, index) => (
        <div className={`home-color-tile-col ${className}`} key={index}>
          <a href={href}>
            <div className="find-color-img">
              <img src={src} alt={title} />
            </div>
            <div className="find-color-text">
              <h4>{title}</h4>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
}

export default latestTrendingDesktop;
